import Vue from 'vue'
// import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueMoment from 'vue-moment'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import common from '@/common'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Instances
import '@/libs/axios'
import '@/libs/axios-wehealth'
import '@/libs/axios-IoT'

// Axios Mock Adapter
import '@/@fake-db/db'

// print environment info
if (process.env.NODE_ENV === 'development' || common.config.is_request_mock === true) {
  console.info({
    precessEnv: process.env,
    is_request_mock: common.config.is_request_mock
  })
}


// Vue Plugins
// Vue.use(ToastPlugin)
// Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(VueCompositionAPI)
Vue.use(VueMoment)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
