/*
* These are just some utilities written by EazycZheng
* */

export class TagManager {
  _tags = new Set()

  adder(tag) {
    this._tags.add(tag)
    return tag
  }

  deleter(tag) {
    this._tags.delete(tag)
    return tag
  }

  get value() {
    return Array.from(this._tags)
  }
}

/*
* getDuplicateItemsFromArray(['a', 'b', 'a', 'a']) // => ['a']
* */
export function getDuplicateItems(items) {
  const duplicateItems = []
  const itemSize = items ? items.length : 0
  if (itemSize > 0) {
    for (let i = 0; i < itemSize; i += 1) {
      for (let j = i + 1; j < itemSize; j += 1) {
        if (duplicateItems.includes(items[i])) {
          break
        } else if (items[i] === items[j]) {
          duplicateItems.push(items[i])
          break
        }
      }
    }
  }
  return duplicateItems
}

export const localStorageApi = {
  getJSON: function (name) {
    let item
    try {
      item = JSON.parse(localStorage.getItem(name))
    } catch (e) {
      console.log(e)
    }
    return item
  },
  setJSON: function (name, obj) {
    let result = false
    try {
      localStorage.setItem(name, JSON.stringify(obj))
      result = true
    } catch (e) {
      console.log(e)
    }
    return result
  }
}

import CryptoRabbit from 'crypto-js/rabbit'
import CryptoEncUtf8 from 'crypto-js/enc-utf8'

export function encrypt(data) {
  return encodeURIComponent(CryptoRabbit.encrypt(JSON.stringify(data), '_xc98!@,SDf|9S/DG)(^!3fDE-=21b.d0~kLE]')
    .toString())
}

export function decrypt(code) {
  try {
    const bytes = CryptoRabbit.decrypt(decodeURIComponent(code), '_xc98!@,SDf|9S/DG)(^!3fDE-=21b.d0~kLE]')
    return JSON.parse(bytes.toString(CryptoEncUtf8))
  } catch (err) {
    console.error(err)
    return undefined
  }
}

// Flatten an object deeply: {"a": {"b": 1}} -> {"a.b": 1}
export function flattenObject(obj) {
  const temp = {}
  for (const mainKey in obj) {
    if (typeof (obj[mainKey]) === 'object' && !Array.isArray(obj[mainKey])) {
      const subObj = flattenObject(obj[mainKey])
      for (const subKey in subObj) {
        temp[`${mainKey}.${subKey}`] = subObj[subKey]
      }
    } else {
      temp[mainKey] = obj[mainKey]
    }
  }
  return temp
}

// Expend an object: {"a.b": 1} -> {"a": {"b": 1}}
export function expandObject(obj) {
  const temp = {}
  for (const key in obj) {
    const chainKeys = key.split('.')
    if (chainKeys.length > 1) {
      _objectChainingDefiner(temp, chainKeys, obj[key])
    } else {
      temp[key] = obj[key]
    }
  }
  return temp
}

/*
* Do some side-effect to 'obj'
* @invoke ({a: {b: 1}}, ['a', 'c'], 2)
* @effect {a: {b: 1}} -> {a: {b: 1, c: 2}}
* */
function _objectChainingDefiner(obj = {}, chainKeys = [], value) {
  const key = chainKeys[0]
  if (chainKeys.length === 1) {
    obj[key] = value
  } else if (chainKeys.length > 1) {
    if (!Reflect.has(obj, key)) {
      obj[key] = {}
    }
    _objectChainingDefiner(obj[key], chainKeys.slice(1), value)
  }
}

/**
 * Truncate a string to a specified length, keeping the head and tail intact,
 * and replacing the middle with '...' if the string exceeds the length.
 *
 * @param {string} str - The original string.
 * @param {number} maxLength - The maximum allowed length of the string.
 * @param {number} truncateFrom - The number of characters to keep at the start of the string.
 * @returns {string} - The truncated string.
 */
export function truncateString(str, maxLength = 10, truncateFrom = 3) {
  // Check if the string needs to be truncated
  if (str.length <= maxLength) {
    return str;
  }

  // Ensure truncateFrom and maxLength parameters are valid
  if (truncateFrom + 3 >= maxLength) {
    throw new Error('truncateFrom must be less than maxLength - 3.');
  }

  // Calculate the number of characters to keep at the end of the string
  const endLength = maxLength - truncateFrom - 3;

  // Construct the truncated string
  return str.slice(0, truncateFrom) + '...' + str.slice(-endLength);
}
