import mocks from '@/@fake-db/mockAdapters'
import samples from '@/@fake-db/responseSamples'
import dbFunctions from '@/@fake-db/dbFunctions'

// A package for generating mock data (https://github.com/nuysoft/Mock/wiki/Syntax-Specification)
import Mock from 'mockjs'

const ApiMap = {

  '/kiosk/WeHealth/GetKioskPeripheralModeList': (config) => {
    const total = 7
    const {
      pageIndex,
      pageSize
    } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({
      total,
      pageIndex,
      pageSize
    })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': {
        total,
        pageIndex,
        pageSize
      },
    }
    let i = pageIndex * pageSize + 1
    template.data[`items|${currentPageSize}`] = [{
      'kioskToken': function () {
        return `Kiosk-M${String(i)
          .padStart(3, '0')}`
      },
      'kioskName': function () {
        return `mockPad-${String(i)
          .padStart(3, '0')}`
      },
      'companyName': '公司1',
      'facilityName': '院舍1-1',
      'peripheralMode|1': ['固定', '自由'],
      'boundPurposes': () => Mock.Random.pick(['NFC', '體溫', '血壓', '血氧', '身高', '體重', '血糖'], 0, 7),
      '_nth': () => i++,
    }]
    return [200, Mock.mock(template)]
  },
  '/kiosk/WeHealth/GetKioskPeripheralMode': (config) => {
    return [200, {
      'return_code': 0,
      'result_msg': 'Success',
      'data': {
        mode: {
          kioskToken: 'Kiosk-M001',
          kioskName: 'mockPad-001',
          companyName: '公司1',
          facilityName: '院舍1-1',
          peripheralMode: 'Fixed',
          fixedPeripherals: [
            {
              modelTag: 'BLE_NFC',
              id: 'E0:07:1D:80:6C:A4',
              idRemark: '會客大廳',
            },
            {
              modelTag: 'TEMP',
              id: '34:14:B5:41:7A:CB',
              idRemark: '會客大廳',
            },
            {
              modelTag: 'AiLink',
              id: '01:B6:EC:D1:C4:83',
              idRemark: '會客大廳',
            },
          ],
        }
      }
    }]
  },
  '/kiosk/WeHealth/UpdateKioskPeripheralMode': () => [200, samples.Success],
  '/kiosk/WeHealth/GetHistoricalDevices': (config) => {
    const items = [
      {
        'bleID': 'E9:CE:C4:E2:A0:29',
        'deviceRemark': null,
        'purposes': [
          'NfcReader'
        ],
        'name': '蓝牙 NFC 读写器',
        'brand': '德科',
        'model': 'DK309',
        'bleName': 'BLE_NFC',
        'modelRemark': null
      },
      {
        'bleID': '04:7F:0E:91:EB:1A',
        'deviceRemark': null,
        'purposes': [
          'Glucose'
        ],
        'name': '蓝牙血糖仪',
        'brand': 'Aicare',
        'model': 'A606',
        'bleName': 'ZH_SG_A606',
        'modelRemark': null
      },
      {
        'bleID': '01:B6:EC:D1:CE:84',
        'deviceRemark': null,
        'purposes': [
          'Weight',
          'Height'
        ],
        'name': '蓝牙身高体重体脂秤',
        'brand': '心动',
        'model': 'HB01-B',
        'bleName': 'AiLink',
        'modelRemark': null
      },
      {
        'bleID': 'CF:E6:15:12:02:93',
        'deviceRemark': null,
        'purposes': [
          'Weight'
        ],
        'name': '蓝牙体重秤',
        'brand': '樂福',
        'model': 'HealthScale',
        'bleName': 'HealthScale',
        'modelRemark': null
      },
      {
        'bleID': '34:14:B5:46:0F:39',
        'deviceRemark': null,
        'purposes': [
          'BodyTemp'
        ],
        'name': '蓝牙体温计',
        'brand': 'Andesfit',
        'model': 'ADF-B38A',
        'bleName': 'TEMP',
        'modelRemark': null
      },
      {
        'bleID': 'A4:C1:38:13:C5:A9',
        'deviceRemark': null,
        'purposes': [
          'BP'
        ],
        'name': '蓝牙血压计',
        'brand': 'SQK',
        'model': 'AOJ-30B',
        'bleName': 'AOJ_30B',
        'modelRemark': null
      },
      {
        'bleID': 'E0:07:1D:80:6C:A4',
        'deviceRemark': null,
        'purposes': [
          'NfcReader'
        ],
        'name': '蓝牙 NFC 读写器',
        'brand': '德科',
        'model': 'DK309',
        'bleName': 'BLE_NFC',
        'modelRemark': null
      },
      {
        'bleID': 'E9:88:41:87:3F:E8',
        'deviceRemark': null,
        'purposes': [
          'NfcReader'
        ],
        'name': '蓝牙 NFC 读写器',
        'brand': '德科',
        'model': 'DK309',
        'bleName': 'BLE_NFC',
        'modelRemark': null
      },
      {
        'bleID': '34:14:B5:45:DC:48',
        'deviceRemark': null,
        'purposes': [
          'BodyTemp'
        ],
        'name': '蓝牙体温计',
        'brand': 'Andesfit',
        'model': 'ADF-B38A',
        'bleName': 'TEMP',
        'modelRemark': null
      },
      {
        'bleID': '0C:AE:7D:AD:1D:D7',
        'deviceRemark': null,
        'purposes': [
          'BodyTemp'
        ],
        'name': '蓝牙体温计',
        'brand': 'Andesfit',
        'model': 'ADF-B38A',
        'bleName': 'TEMP',
        'modelRemark': null
      },
      {
        'bleID': 'A4:C1:38:74:F5:69',
        'deviceRemark': null,
        'purposes': [
          'BO'
        ],
        'name': '蓝牙血氧仪',
        'brand': 'SQK',
        'model': 'AOJ-70B',
        'bleName': 'AOJ_70B',
        'modelRemark': null
      },
      {
        'bleID': '01:B6:EC:D1:C4:83',
        'deviceRemark': null,
        'purposes': [
          'Weight',
          'Height'
        ],
        'name': '蓝牙身高体重体脂秤',
        'brand': '心动',
        'model': 'HB01-B',
        'bleName': 'AiLink',
        'modelRemark': null
      },
      {
        'bleID': '01:B6:EC:D1:C4:83',
        'deviceRemark': null,
        'purposes': [
          'Weight',
          'Height'
        ],
        'name': '蓝牙身高体重体脂秤',
        'brand': '心动',
        'model': 'HB01-B',
        'bleName': 'AiLink',
        'modelRemark': null
      },
      {
        'bleID': '10:CE:A9:35:6D:57',
        'deviceRemark': null,
        'purposes': [
          'BodyTemp'
        ],
        'name': '蓝牙体温计',
        'brand': 'Andesfit',
        'model': 'ADF-B38A',
        'bleName': 'TEMP',
        'modelRemark': null
      }
    ]
    const total = items.length
    const {
      pageIndex,
      pageSize
    } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({
      total,
      pageIndex,
      pageSize
    })
    return [200, {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { items }
    }]
  },

  '/kiosk/WeHealth/GetKioskPeripheralModelList': (config) => {
    const items = [
      {
        'modelTag': 'BLE_NFC',
        'purposes': ['NfcReader'],
        'name': '藍牙NFC讀寫器',
        'brand': '德科',
        'model': 'DK309',
        'remark': '價格偏高',
      },
      {
        'modelTag': 'TEMP',
        'purposes': ['BodyTemp'],
        'name': '藍牙體溫計',
        'brand': 'Andesfit',
        'model': 'ADF-B38A',
        'remark': '品控一般，某些設備連接不穩定，價格偏高',
      },
      {
        'modelTag': 'AOJ_30B',
        'purposes': ['BP'],
        'name': '藍牙血壓計',
        'brand': 'SQK',
        'model': 'AOJ-30B',
        'remark': '',
      },
      {
        'modelTag': 'AOJ_70B',
        'purposes': ['BO'],
        'name': '藍牙血氧儀',
        'brand': 'SQK',
        'model': 'AOJ-70B',
        'remark': '',
      },
      {
        'modelTag': 'AiLink',
        'purposes': ['Weight', 'Height'],
        'name': '藍牙身高體重體脂秤',
        'brand': '心動',
        'model': 'HB01-B',
        'remark': '測量速度較慢',
      },
      {
        'modelTag': 'ZH_SG_A606',
        'purposes': ['Glucose'],
        'name': '藍牙血糖儀',
        'brand': 'Aicare',
        'model': 'A606',
        'remark': '測量出錯時可嘗試先清除歷史記錄',
      },
    ]
    const total = items.length
    const {
      pageIndex,
      pageSize
    } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({
      total,
      pageIndex,
      pageSize
    })
    return [200, {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { items }
    }]
  },
  '/kiosk/WeHealth/GetKioskPeripheralModel': (config) => {
    return [200, {
      'return_code': 0,
      'result_msg': 'Success',
      'data': {
        model: {
          modelTag: 'AiLink',
          purposes: ['Weight', 'Height'],
          name: '藍牙身高體重體脂秤',
          brand: '心動',
          model: 'HB01-B',
          remark: '品控優良，連接穩定，價格合理，測量速度較慢',
        }
      }
    }]
  },
  '/kiosk/WeHealth/AddKioskPeripheralModel': () => [200, samples.Success],
  '/kiosk/WeHealth/UpdateKioskPeripheralModel': () => [200, samples.Success],
  '/kiosk/WeHealth/DeleteKioskPeripheralModel': () => [200, samples.Success],
}

mocks.forEach(mock => {
  for (const api in ApiMap) {
    mock.onAny(new RegExp(`${api}\\b`))
      .reply(ApiMap[api])
  }
})
