import Vue from 'vue'
import axios from 'axios'
import common from '@/common'
import requestWehealth from './axios-wehealth'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL_IOT + 'api',
  timeout: common.config.api_timeout,
  headers: common.getApiHeaders(),
})

requestWehealth.interceptors.request.handlers?.forEach(handler => axiosIns.interceptors.request.use(handler))
requestWehealth.interceptors.response.handlers?.forEach(handler => axiosIns.interceptors.response.use(handler))

Vue.prototype.$requestIoT = axiosIns
export default axiosIns
