export default [
  {
    name: 'geoFencingRecord',
    path: '/geoFencingRecord',
    component: () => import('@/views/geoFencing/gfRecord/Index')
  },
  {
    name: 'geoFencingAntennaEnvironment',
    path: '/geoFencingAntennaEnvironment',
    component: () => import('@/views/geoFencing/gfAntennaEnvironment/Index')
  },
  {
    name: 'geoFencingAntennaEnvironment_add',
    path: '/geoFencingAntennaEnvironment/add',
    component: () => import('@/views/geoFencing/gfAntennaEnvironment/Add')
  },
  {
    name: 'geoFencingAntennaEnvironment_edit',
    path: '/geoFencingAntennaEnvironment/edit/:mac/:env/:name',
    component: () => import('@/views/geoFencing/gfAntennaEnvironment/Edit')
  },
  {
    name: 'geoFencingAntennaFacility',
    path: '/geoFencingAntennaFacility',
    component: () => import('@/views/geoFencing/gfAntennaFacility/Index')
  },
  {
    name: 'geoFencingAntennaFacility_edit',
    path: '/geoFencingAntennaFacility/edit/:id',
    component: () => import('@/views/geoFencing/gfAntennaFacility/Edit')
  },
  {
    name: 'geoFencingRfidManage',
    path: '/geoFencingRfidManage',
    component: () => import('@/views/geoFencing/gfRfidManage/Index')
  },
  {
    name: 'geoFencingRfidManage_edit',
    path: '/geoFencingRfidManage/edit/:id',
    component: () => import('@/views/geoFencing/gfRfidManage/Edit')
  },
  {
    name: 'geoFencingReport',
    path: '/geoFencingReport',
    meta: {
      // hideInNodeEnvProduction: true
    },
    component: () => import('@/views/geoFencing/gfReport/Index')
  },
  {
    name: 'geoFencingRawDown',
    path: '/geoFencingRawDown',
    meta: {
      // hideInNodeEnvProduction: true
    },
    component: () => import('@/views/geoFencing/gfRawDown/Index')
  },
  {
    name: 'geoFencingAntennaBatchUpload',
    path: '/geoFencingAntennaBatchUpload',
    meta: {
      // hideInNodeEnvProduction: true
    },
    component: () => import('@/views/geoFencing/gfAntennaBatchUpload/Index')
  },
  {
    name: 'geoFencingRfidBatchUpload',
    path: '/geoFencingRfidBatchUpload',
    meta: {
      // hideInNodeEnvProduction: true
    },
    component: () => import('@/views/geoFencing/gfRfidBatchUpload/Index')
  },
  {
    name: 'geoFencingReset',
    path: '/geoFencingReset',
    meta: {
      // hideInNodeEnvProduction: true
    },
    component: () => import('@/views/geoFencing/gfReset/Index')
  },
]
