import mocks from '@/@fake-db/mockAdapters'

mocks.forEach(mock => {
  mock.onAny(new RegExp('/bedtype/GetTypeDropDownList\\b')).reply(() => [200, GetTypeDropDownList])
  mock.onAny(new RegExp('/bedtype/GetTypeList\\b')).reply(() => [200, GetTypeList])
  mock.onAny(new RegExp('/bedtype/GetType\\b')).reply(() => [200, GetType])
  mock.onAny(new RegExp('/bedtype/AddType\\b')).reply(() => [200, AddType])
  mock.onAny(new RegExp('/bedtype/UpdateType\\b')).reply(() => [200, UpdateType])
  mock.onAny(new RegExp('/bedtype/DeleteBedType\\b')).reply(() => [200, DeleteBedType])
})


const GetTypeList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "total": 3,
    "pageIndex": 0,
    "pageSize": 0,
    "bedTypes": [
      {
        "typeID": 1,
        "typeName": "普通床",
        "isActive": true
      },
      {
        "typeID": 2,
        "typeName": "醫療床",
        "isActive": false
      },
    ]
  }
}

const GetType = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "bedType": {
      "typeID": 3,
      "typeName": "普通床",
      "isActive": true
    }
  }
}

const AddType = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "floorID": 4
  }
}

const UpdateType = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const DeleteBedType = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const GetTypeDropDownList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "types": [
      {
        "typeID": 1,
        "typeName": "類型1"
      },
      {
        "typeID": 2,
        "typeName": "類型3"
      },
    ]
  }
}

