import mocks from '@/@fake-db/mockAdapters'
import samples from '@/@fake-db/responseSamples'

const ApiMap = {
  // Record
  '/geofencing/GetArmedList': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 3,
      'pageIndex': 0,
      'pageSize': 0,
      'geoFencings': [{
        'armedID': 8,
        'memberID': 2,
        'bedID': 2,
        'memberPhoto': null,
        'memberName': '張叁',
        'floorName': '2/F',
        'roomName': '2-01',
        'bedNumber': '2-01-101',
        'armedTime': '2022-11-25T15:25:00',
        'followedTime': null,
        'followedStaff': null,
        'isConfirmed': false,
        'location': '東門'
      }, {
        'armedID': 3,
        'memberID': 2,
        'bedID': 2,
        'memberPhoto': 'files/MemberPhoto/MemberPhoto_29_20220526154455893.png',
        'memberName': '李肆',
        'floorName': '1/F',
        'roomName': '1-01',
        'bedNumber': '1-01-101',
        'armedTime': '2022-05-28T14:18:55',
        'followedTime': null,
        'followedStaff': null,
        'isConfirmed': false,
        'location': '南門'
      }, {
        'armedID': 10,
        'memberID': 2,
        'bedID': 2,
        'memberPhoto': null,
        'memberName': '張叁',
        'floorName': '2/F',
        'roomName': '2-01',
        'bedNumber': '2-01-101',
        'armedTime': '2022-05-25T12:25:00',
        'followedTime': null,
        'followedStaff': null,
        'isConfirmed': false,
        'location': '東門'
      }, {
        'armedID': 2,
        'memberID': 2,
        'bedID': 2,
        'memberPhoto': 'files/MemberPhoto/MemberPhoto_29_20220511171624975.png',
        'memberName': '陳伍',
        'floorName': '1/F',
        'roomName': '1-01',
        'bedNumber': '1-01-103',
        'armedTime': '2022-10-27T03:18:33',
        'followedTime': '2022-10-27T15:29:30',
        'followedStaff': '田婷婷',
        'isConfirmed': true,
        'location': '南門'
      }, {
        'armedID': 1,
        'memberID': 2,
        'bedID': 2,
        'memberPhoto': 'files/MemberPhoto/MemberPhoto_29_20220512181701530.png',
        'memberName': '趙陸',
        'floorName': '1/F',
        'roomName': '1-01',
        'bedNumber': '1-01-102',
        'armedTime': '2022-10-17T10:08:33',
        'followedTime': '2022-10-17T17:39:30',
        'followedStaff': '江佳佳',
        'isConfirmed': true,
        'location': '南門'
      }, {
        'armedID': 4,
        'memberID': 2,
        'bedID': 2,
        'memberPhoto': null,
        'memberName': '梁柒',
        'floorName': '1/F',
        'roomName': '1-01',
        'bedNumber': '1-01-107',
        'armedTime': '2022-10-24T03:18:33',
        'followedTime': '2022-10-25T15:29:30',
        'followedStaff': '江佳佳',
        'isConfirmed': true,
        'location': '北門'
      },]
    }
  }],
  '/geofencing/ExportGeoFencingList': () => [200, samples.IoTAccessError],
  '/geofencing/ConfirmArmed': () => [200, samples.Success],
  '/geofencing/ConfirmMemberArmeds': () => [200, samples.Success],
  '/geofencing/ConfirmAllArmeds': () => [200, samples.Success],

  // AntennaEnvironment
  '/geofencing/GetGeoFencingList': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 4,
      'pageIndex': 0,
      'pageSize': 0,
      'geoFencings': [{
        'deviceMAC': 'AD01000EC2000607',
        'deviceName': '南門',
        'isUAT': true
      },
        {
          'deviceMAC': 'AD01000EC2000605',
          'deviceName': '東門',
          'isUAT': true
        },
        {
          'deviceMAC': 'AD01000EC2000603',
          'deviceName': '側門',
          'isUAT': false
        },
        {
          'deviceMAC': 'AD01000EC2000601',
          'deviceName': '正門',
          'isUAT': false
        }
      ]
    }
  }],
  '/geofencing/AddGeoFencing': () => [200, samples.Success],
  '/geofencing/AssignGeoFencing': () => [200, samples.Success],
  '/geofencing/DeleteGeoFencing': () => [200, samples.Success],

  // AntennaFacility
  '/geofencing/GetFacilityGeoFencingList': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 4,
      'pageIndex': 0,
      'pageSize': 0,
      'geoFencings': [
        {
          'companyName': '公司1',
          'facilityName': '院舍1',
          'geoFencingID': 1,
          'deviceMAC': 'AD01000EC2000607',
          'deviceName': '南門',
        },
        {
          'companyName': '公司1',
          'facilityName': '院舍1',
          'geoFencingID': 2,
          'deviceMAC': 'AD01000EC2000605',
          'deviceName': '東門',
        },
        {
          'status': 'Offline',
          'companyName': '公司1',
          'facilityName': '院舍1',
          'geoFencingID': 3,
          'deviceMAC': 'AD01000EC2000603',
          'deviceName': '側門',
        },
        {
          'companyName': '公司1',
          'facilityName': '院舍1',
          'geoFencingID': 4,
          'deviceMAC': 'AD01000EC2000601',
          'deviceName': '正門',
        },
        {
          'companyName': null,
          'facilityName': null,
          'geoFencingID': 9,
          'deviceMAC': 'AD01000EC2000609',
        }
      ]
    }
  }],
  '/geofencing/GetFacilityGeoFencing': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'geoFencing': {
        'geoFencingID': 1,
        'deviceMAC': 'AD01000EC2000601',
        'deviceName': '正門',
        'companyID': 1,
        'facilityID': 11,
      }
    }
  }],
  '/geofencing/UpdateFacilityGeoFencing': () => [200, samples.Success],

  // RfidEnvironment
  '/geoFencing/GetRfidList': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 4,
      'pageIndex': 0,
      'pageSize': 0,
      'items': [
        {
          'deviceMAC': 'AD01000EC2000607',
          'deviceName': 'RFID-No.01',
          'isUAT': true
        },
        {
          'deviceMAC': 'AD01000EC2000605',
          'deviceName': 'RFID-No.02',
          'isUAT': true
        },
        {
          'deviceMAC': 'AD01000EC2000603',
          'deviceName': 'RFID-No.03',
          'isUAT': true
        },
        {
          'deviceMAC': 'AD01000EC2000601',
          'deviceName': 'RFID-No.04',
          'isUAT': false
        }
      ]
    }
  }],
  '/geoFencing/AddRfid': () => [200, samples.Success],
  '/geoFencing/AssignRfid': () => [200, samples.Success],
  '/geoFencing/DeleteRfid': () => [200, samples.Success],

  // RfidManage
  '/geoFencing/UpdateRfid': () => [200, samples.Success],
  '/memberrfid/GetMemberRfidInfo': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'member': {
        'companyName': '夕陽紅養老集團',
        'facilityName': '夕阳红唯一院舍',
        'floorName': '5楼',
        'roomName': '502',
        'memberCode': 'SSR0100005',
        'rfidTags': [
          'FFEEDDCCBBAA998877660005',
          'FFEEDDCCBBAA998877660099'
        ],
        'memberID': 6,
        'chineseName': '陈6文',
        'englishName': 'Chan Wan Wan',
        'bedNumber': '502-1'
      }
    }
  }],
  '/memberrfid/UpdateRfid': () => [200, samples.Success],
  '/memberrfid/GetMemberRfids': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 2,
      'pageIndex': 0,
      'pageSize': 0,
      'memberRfids': [
        {
          'rfidTags': 'A02226000003A02226000002,A02226000003A02226000003,A02226000003A02226000004',
          'floorName': '5楼',
          'roomName': '501',
          'photo': 'files/MemberPhoto/MemberPhoto_1_20220630182432241.jpg',
          'status': 'MSF',
          'memberID': 1,
          'chineseName': '陈大大da文',
          'englishName': 'Chan Da Wan',
          'bedNumber': '501-5'
        },
        {
          'rfidTags': null,
          'floorName': '5楼',
          'roomName': '501',
          'photo': 'files/MemberPhoto/MemberPhoto_1_20220630182432241.jpg',
          'status': 'MSF',
          'memberID': 2,
          'chineseName': '陈大大da文2',
          'englishName': 'Chan Da Wan2',
          'bedNumber': '501-5'
        }
      ]
    }
  }],

  // RfidFacility
  '/geoFencing/GetFacilityRfidList': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 4,
      'pageIndex': 0,
      'pageSize': 0,
      'devices': [
        {
          'companyName': '公司1',
          'facilityName': '院舍1',
          'deviceID': 1,
          'deviceMAC': 'AD01000EC2000607',
          'deviceName': 'RFID-No.01',
          'memberID': 16,
          'memberCode': 'M016',
          'chineseName': '田婷婷',
          'englishName': 'Tingting',
        },
        {
          'companyName': '公司1',
          'facilityName': '院舍1',
          'deviceID': 2,
          'deviceMAC': 'AD01000EC2000605',
          'deviceName': 'RFID-No.02',
          'memberID': 17,
          'memberCode': 'M017',
          'chineseName': '江佳佳',
          'englishName': 'Jiajia',
        },
        {
          'companyName': '公司1',
          'facilityName': '院舍1',
          'deviceID': 3,
          'deviceMAC': 'AD01000EC2000603',
          'deviceName': 'RFID-No.03',
          'memberID': 18,
          'memberCode': 'M018',
          'chineseName': '阿朱',
          'englishName': 'Arjuna',
        },
      ]
    }
  }],
  '/geoFencing/GetFacilityRfid': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'device': {
        'deviceID': 1,
        'deviceMAC': 'AD01000EC2000601',
        'deviceName': '正門',
        'companyID': 1,
        'facilityID': 11,
      }
    }
  }],
  '/geoFencing/UpdateFacilityRfid': () => [200, samples.Success],

  // Report
  '/geoFencing/GetWanderingReport': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': null
  }],

  // RawDataDownload
  '/geofencing/GetGeoFencings': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'geoFencings': [
        {
          'geoFencingID': 1,
          'deviceMAC': 'FFEEDDCCBBAA0001',
          'deviceName': '大门'
        },
        {
          'geoFencingID': 2,
          'deviceMAC': 'FFEEDDCCBBAA0002',
          'deviceName': '小门'
        },
        {
          'geoFencingID': 4,
          'deviceMAC': 'FFEEDDCCBBAA0003',
          'deviceName': '杂物房'
        }
      ]
    }
  }],
  '/geofencing/DownloadRawData': () => [200, samples.IoTAccessError],

  // BatchUpload
  '/batch/GetGeoFencingSamplePath': () => [200, samples.Success],
  '/batch/BatchRfids': () => [200, samples.Success],
  '/batch/BatchGeoFencings': () => [200, samples.Success],
}

mocks.forEach(mock => {
  for (const api in ApiMap) {
    mock.onAny(new RegExp(`${api}\\b`))
      .reply(ApiMap[api])
  }
})
