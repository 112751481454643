import mocks from '@/@fake-db/mockAdapters'

mocks.forEach(mock => {
  mock.onAny(new RegExp('/bed/GetBedDropDownList\\b')).reply(() => [200, GetBedDropDownList])
  mock.onAny(new RegExp('/bed/GetBedList\\b')).reply(() => [200, GetBedList])
  mock.onAny(new RegExp('/bed/GetBed\\b')).reply(() => [200, GetBed])
  mock.onAny(new RegExp('/bed/AddBed\\b')).reply(() => [200, AddBed])
  mock.onAny(new RegExp('/bed/UpdateBed\\b')).reply(() => [200, UpdateBed])
  // mock.onAny(new RegExp('/bed/DeleteBed\\b')).reply(() => [200, DeleteBed])
})


const GetBedList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "total": 3,
    "pageIndex": 0,
    "pageSize": 0,
    "beds": [
      {
        "bedID": 1,
        "floorName": '樓層1',
        "roomName": '房間1',
        "bedNumber": '床號1',
        "isActive": true
      },
      {
        "bedID": 2,
        "floorName": '樓層2',
        "roomName": '房間2',
        "bedNumber": '床號2',
        "isActive": false
      },
    ]
  }
}

const GetBed = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "bed": {
      "companyID": 1,
      "facilityID": 11,
      "floorID": 1,
      "roomID": 1,
      "bedNumber": '101',
      "bedTypeID": 1,
      "isActive": true
    }
  }
}

const AddBed = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "floorID": 4
  }
}

const UpdateBed = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const DeleteBed = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const GetBedDropDownList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "beds": [
      {
        "bedID": 1,
        "bedNumber": "床位1"
      },
      {
        "bedID": 2,
        "bedNumber": "床位3"
      },
    ]
  }
}
