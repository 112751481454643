<template>
  <div id="app" class="h-100" :class="[skinClasses]">

    <component :is="layout">
      <router-view ref="router-view"/>
    </component>

    <!--    <scroll-to-top />-->

    <div id="loading_box" class="loading-box" v-show="loading">
      <b-spinner class="loading-box-spinner" variant="primary"/>
    </div>
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import { BSpinner } from 'bootstrap-vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'

import { ref, computed, watch, onBeforeMount, onMounted, onBeforeUnmount, onUnmounted, getCurrentInstance, provide } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { useWindowSize, useCssVar } from '@vueuse/core'

import i18n from '@/libs/i18n/index'
import { localize } from 'vee-validate'
import en from '@/libs/i18n/vee-validate/en.json'
import zh_HK from '@/libs/i18n/vee-validate/zh_HK.json'
// import en from 'vee-validate/dist/locale/en.json'
// import zh_TW from 'vee-validate/dist/locale/zh_TW.json'
// import zh_CN from 'vee-validate/dist/locale/zh_CN.json'

import store from '@/store'
import common from '@/common'

import Vue from 'vue'
import { srHub, srHubState, srStart, srStop, srUpdateFilter, srEventMap } from '@/libs/signalr-hub'
import { TagManager } from '@/libs/ez-utils'
import router from './router'
import ToastRows from '@/components/ToastRows'

const LayoutVertical = () => import('@/layouts/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/LayoutFull.vue')

export default {
  name: 'App',
  components: {
    LayoutVertical,
    LayoutFull,
    ScrollToTop,
    BSpinner,
  },

  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') {
        return 'layout-full'
      } else {
        return 'layout-vertical'
      }
    },
    loading() {
      return this.$store.state.app.loadingNum > 0
      // return this.$store.state.app.showLoading
    },
  },

  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement)
        .value
        .trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement)
        .value
        .slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },

  created() {
    window.__WH_APP__ = this
    window.__WH_COMMON__ = common
  },

  setup() {

    const appVm = getCurrentInstance()
    const {
      skin,
      skinClasses
    } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    common.refreshStore()

    // vee-validate language setup
    switch (i18n.locale) {
      case 'zh_HK': localize('zh_HK', zh_HK); break;
      case 'en': localize('en', en); break;
      // case 'zh_TW': localize('zh_TW', zh_TW); break;
      // case 'zh_CN': localize('zh_CN', zh_CN); break;
      default: localize('zh_HK', zh_HK);
    }

    // other initializations
    const matchedRoute = router.options.routes.find(route => route.path === location.pathname)
    const isAccessPageStandalone = matchedRoute?.meta?.isStandalone ?? false
    if (!isAccessPageStandalone) {
      // handle network changed
      {
        const isOnline = computed(() => store.state.app.network.isOnline.value)
        watch(isOnline, (newVal, _oldVal) => {
          console.log('Network changed: ', newVal ? 'online' : 'offline')
          srStop(newVal, 0)
        })
      }

      // handle storage changed (only from another tab)
      {
        function handleStorageChange(event) {
          if (event.key === 'deploy_version') {
            if (common.getAccessToken() === null) { // logout from another tab
              srStop(false)
            } else { // re-login from another tab
              common.showLoading()
              location.reload() // reload page in case of account's menu or permissions changed
            }
          }
        }
        onMounted(async () => {
          // this event will only be triggered when the storage of another tab changes
          window.addEventListener('storage', handleStorageChange)
        })
        onUnmounted(async () => {
          window.removeEventListener('storage', handleStorageChange)
        })
      }

      // SignalR
      if(common.config.is_signalr_enabled) {
        const srEventTags = new TagManager()

        // SignalR - common
        {
          function handleBeforeunload(_event) {
            srStop(false)
          }

          onMounted(async () => {
            window.addEventListener('beforeunload', handleBeforeunload)
            srHub.on(srEventTags.adder(srEventMap.OnConnected), data => {
              console.log('SignalR "OnConnected": ', data)
            })
            srHub.on(srEventTags.adder(srEventMap.Message), data => {
              console.log('SignalR "Message": ', data)
            })
            if (common.getAccessToken() !== null) {
              await srStart(true)
            }
          })
          onUnmounted(async () => {
            srEventTags.value.forEach(eventName => {
              srHub.off(eventName) // remove listeners to prevent duplicate listening
            })
            window.removeEventListener('beforeunload', handleBeforeunload)
            await srStop(false)
          })
        }

        // SignalR - CallBell
        {
          let toastIds = []
          const toastCallBell = function (data) {
            Vue.prototype.$toast({
              component: ToastRows,
              props: {
                variant: 'danger',
                title: common.getI18n('call_bell.call_bell_notification'),
                rows: [
                  common.getI18n('call_bell.call_bell_name') + ': ' + data.callBellName,
                  common.getI18n('member.title') + ': ' + data.memberName + '(' + data.bedNumber + ')',
                  common.getI18n('common.time') + ': ' + Vue.prototype.$moment(data.armedTime)
                    .format('YYYY-MM-DD HH:mm:ss')
                ]
              },
              listeners: {
                confirm: () => {
                  toastIds
                    .forEach((id) => {
                      Vue.prototype.$toast.dismiss(id)
                      store.commit('app/DEC_CALL_BELL_TOAST_NUM')
                    })
                  toastIds = []
                  const destination = 'callBellRecord'
                  if (router.history.current.name !== destination) {
                    router.push({ name: destination })
                  }
                }
              }
            }, {
              timeout: false,
              draggable: false,
              id: store.state.app.toastIdTemp,
            })
            toastIds.push(store.state.app.toastIdTemp)
            store.commit('app/INC_TOAST_ID')
            store.commit('app/INC_CALL_BELL_TOAST_NUM')
          }
          onMounted(() => {
            srHub.on(srEventTags.adder(srEventMap.CallBellArmed), data => {
              console.log('SignalR "CallBellArmed": ', data)
              toastCallBell(data)
            })
          })
        }

        // SignalR - GeoFencing
        {
          let toastIds = []
          const toastGeoFencing = function (data) {
            Vue.prototype.$toast({
              component: ToastRows,
              props: {
                variant: 'danger',
                title: common.getI18n('geo_fencing.notification'),
                rows: [
                  common.getI18n('geo_fencing.antenna_name') + ': ' + data.location,
                  common.getI18n('member.title') + ': ' + data.memberName + '(' + data.bedNumber + ')',
                  common.getI18n('common.time') + ': ' + Vue.prototype.$moment(data.armedTime)
                    .format('YYYY-MM-DD HH:mm:ss')
                ]
              },
              listeners: {
                confirm: () => {
                  toastIds
                    .forEach((id) => {
                      Vue.prototype.$toast.dismiss(id)
                      store.commit('app/DEC_GEO_FENCING_TOAST_NUM')
                    })
                  toastIds = []
                  const destination = 'geoFencingRecord'
                  if (router.history.current.name !== destination) {
                    router.push({ name: destination })
                  }
                }
              }
            }, {
              timeout: false,
              draggable: false,
              id: store.state.app.toastIdTemp,
            })
            toastIds.push(store.state.app.toastIdTemp)
            store.commit('app/INC_TOAST_ID')
            store.commit('app/INC_GEO_FENCING_TOAST_NUM')
          }
          onMounted(() => {
            srHub.on(srEventTags.adder(srEventMap.GeoFencingArmed), data => {
              console.log('SignalR "GeoFencingArmed": ', data)
              toastGeoFencing(data)
            })
          })
        }
      }
    }

    return {
      skinClasses,
      enableScrollToTop,
      srHub,
      srHubState,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
