export default {
  getSearchParams(config) {
    const params = config.params || Object.fromEntries(new URLSearchParams(config.url.split('?')[1])) || {}
    params.pageIndex = typeof params.pageIndex === 'string' ? Number(params.pageIndex) : params.pageIndex
    params.pageSize = typeof params.pageSize === 'string' ? Number(params.pageSize) : params.pageSize
    return Object.assign({ pageIndex: 0, pageSize: 20 }, params)
  },

  getCurrentPageSize({ total, pageIndex, pageSize }) {
    const pageIndexMax = Math.ceil(total / pageSize) - 1
    return pageIndex < pageIndexMax ? pageSize : total - pageSize * pageIndex
  },

  // Generate a random datetime between the start and end dates, defaulting to the last 1 year.
  getRandomDatetime(start = new Date(new Date().setFullYear(new Date().getFullYear() - 1)), end = new Date()) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
  }
}
