import Vue from 'vue'
// import common from '@/common'
import globalConfig from '@/global-config'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

if (globalConfig.is_request_mock) {
  axiosIns.interceptors.response.use(
    (res) => {
      let requestData = {}
      console.group('Mock', res.request.responseURL)
      try {
        if (typeof (res.config.data) === 'string') {
          requestData = JSON.parse(res.config.data)
        } else if (res.config.data instanceof FormData) {
          requestData = Object.fromEntries(res.config.data.entries())
        } else {
          requestData = res.config.data
        }
        res.config.dataObj = requestData
      } catch (err) {
        console.log(err)
      }
      console.log(res)
      console.groupEnd()
      return res
    },
    (error) => {
      console.group('Mock', error.config.url)
      console.dir(error)
      console.groupEnd()
      return Promise.reject(error)
    })
}

Vue.prototype.$request = axiosIns

export default axiosIns
