export default {
  Success: {
    'return_code': 0,
    'result_msg': '',
    'data': {
      'message': 'hello'
    }
  },
  ColumnLengthNotCorrect: {
    'return_code': 10316,
    'result_msg': 'ColumnLengthNotCorrect',
    'data': {
      'errorRow': 2,
      'errorContent': 'abcdefg'
    }
  },
  UnknownError: {
    'return_code': 10001,
    'result_msg': 'UnknownError',
    'data': {
      'message': '(some error msg)'
    }
  },
  GatewayTimeout_200: {
    'return_code': 10001,
    'result_msg': 'UnknownError',
    'data': 'Request failed with status code GatewayTimeout'
  },
  IoTAccessError: {
    'return_code': 10009,
    'result_msg': 'IoTAccessError',
    'data': null
  },
  Code_400: {
    'type': 'https://tools.ietf.org/html/rfc7231#section-6.5.1',
    'title': 'One or more validation errors occurred.',
    'status': 400,
    'traceId': '|50ff4eaa-4ad321ba6a439a83.',
    'errors': {
      'ChineseAddress': ['The max length of ChineseAddress is 150.'],
      'ChineseName': ['The max length of ChineseName is 50.']
    }
  }
}
