import Mock from 'mockjs'
import mocks from '@/@fake-db/mockAdapters'
import samples from '@/@fake-db/responseSamples'
import common from '@/common'

const ApiMap = {
  '/_demo/Random': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'email': Mock.mock('@email')
    }
  }],

  '/_demo/Success': () => [200, samples.Success],

  '/_demo/ColumnLengthNotCorrect': () => [200, samples.ColumnLengthNotCorrect],

  '/_demo/UnknownError': () => [200, samples.UnknownError],

  '/_demo/Code_101': () => [101, {}],

  '/_demo/Code_300': () => [300, {}],

  '/_demo/Code_400': () => [400, samples.Code_400],

  '/_demo/Code_401': () => [401, {}],

  '/_demo/Code_404': () => [404, {}],

  '/_demo/Code_500': () => [500, {}],

  '/_demo/Random_In_1s': () => new Promise(function (resolve, reject) {
    setTimeout(function () {
      const random = Math.random()
      if (random > 0.8) {
        resolve(samples.Success)
      } else if (random > 0.6) {
        resolve(samples.ColumnLengthNotCorrect)
      } else if (random > 0.4) {
        // reject() reason will be passed as-is.
        // Use HTTP error status code to simulate server failure.
        resolve([500, { success: false }])
      } else if (random > 0.2) {
        resolve([400, samples.Code_400])
      } else {
        resolve(samples.UnknownError)
      }
    }, 500)
  }),

  '/_demo/Random_Immediately': () => new Promise(function (resolve, reject) {
    const random = Math.random()
    if (random > 0.8) {
      resolve(samples.Success)
    } else if (random > 0.6) {
      resolve(samples.ColumnLengthNotCorrect)
    } else if (random > 0.4) {
      // reject() reason will be passed as-is.
      // Use HTTP error status code to simulate server failure.
      resolve([500, { success: false }])
    } else if (random > 0.2) {
      resolve([400, samples.Code_400])
    } else {
      resolve(samples.UnknownError)
    }
  }),
}

mocks.forEach(mock => {
  for (const api in ApiMap) {
    mock.onAny(common.apiPrepareUrl(api))
      .reply(ApiMap[api])
  }
  mock.onAny(common.apiPrepareUrl('/_demo/TimeOut'))
    .timeout()
  mock.onAny(common.apiPrepareUrl('/_demo/NetworkError'))
    .networkError()
})
