export default [
  '/WebLogin/GenToken',

  '/company/GetCompanyList',
  '/company/GetCompany',
  '/company/AddCompany',
  '/company/UpdateCompany',
  '/company/DeleteCompany',
  '/company/GetCompanyDropDownList',

  '/facility/GetFacilityList',
  '/facility/GetFacility',
  '/facility/AddFacility',
  '/facility/UpdateFacility',
  '/facility/DeleteFacility',
  '/facility/GetModulesAndOtherConfigs',
  '/facility/GetFacilityAndRoleList',
  '/facility/GetFacilityDropDownList',
  '/facility/GetFacilityAvailableModules',

  '/staff/GetMenus',
  '/staff/GetProfile',
  '/staffmanage/GetStaffList',
  '/staffmanage/GetStaff',
  '/staffmanage/AddStaff',
  '/staffmanage/UpdateStaff',
  '/staffmanage/DeleteStaff',
  '/staffmanage/EditStaffPassword',
  '/staff/GetPermissions',
  '/staff/ChangePassword',
  '/staff/EditProfile',

  '/bedtype/GetTypeDropDownList',
  '/bedtype/GetTypeList',
  '/bedtype/GetType',
  '/bedtype/AddType',
  '/bedtype/UpdateType',
  '/bedtype/DeleteBedType',

  '/bedfloor/GetRoomDropDownList',
  '/bedfloor/GetFloorDropDownList',
  '/bedfloor/GetFloorList',
  '/bedfloor/GetFloor',
  '/bedfloor/AddFloor',
  '/bedfloor/UpdateFloor',
  '/bedfloor/UpdateFloorSeq',

  '/bed/GetBedDropDownList',
  '/bed/GetBedList',
  '/bed/GetBed',
  '/bed/AddBed',
  '/bed/UpdateBed',

  '/member/GetMemberStatusDropDownList',
  '/member/GetMemberList',
  '/member/GetMember',
  '/member/AddMember',
  '/member/UpdateMember',
  '/member/DeleteMember',
  '/memberphoto/AddPhoto',
  '/memberphoto/UpdatePhoto',

  '/group/GetStaffs',
  '/group/GetBedMembers',
  '/group/GetMemberBeds',
  '/group/GetGroupList',
  '/group/GetGroup',
  '/group/AddGroup',
  '/group/UpdateGroup',
  '/group/GetGroupDropDownList',

  '/role/GetRoleList',
  '/role/GetRole',
  '/role/AddRole',
  '/role/UpdateRole',
  '/role/DeleteRole',

  '/bemonitor/GetMonitorOverview',
  '/bemonitor/GetMonitorMemberInfo',
  '/bemonitor/GetMonitorMemberRecords',

  '/bemonitor/GetMonitorList',
  '/bemonitor/AddMonitor',
  '/bemonitor/AssignMonitor',
  '/bemonitor/DeleteMonitor',

  '/bemonitor/GetFacilityMonitorList',
  '/bemonitor/GetFacilityMonitor',
  '/bemonitor/UpdateFacilityMonitor',

  '/bemonitor/GetForceSettingMonitorList',
  '/bemonitor/MonitorForceSetting',

  '/bemonitor/GetReportChartGuidePath',
  '/bemonitor/GetStatusReport',
  '/bemonitor/GetPersonalRecordReport',
  '/bemonitor/GetSleepCycleReport',
  '/bemonitor/GetTimePeriodStatReport',

  '/notice/GetNoticeTypeDropDownList',
  '/notice/GetNoticeList',

  '/admin/GetAdminList',
  '/admin/GetAdmin',
  '/admin/AddAdmin',
  '/admin/UpdateAdmin',
  '/admin/StartImpersonate',
  '/admin/StopImpersonate'





]
