import mocks from '@/@fake-db/mockAdapters'

mocks.forEach(mock => {
  mock.onAny(new RegExp('/group/GetGroupDropDownList\\b')).reply(() => [200, GetGroupDropDownList])
  mock.onAny(new RegExp('/group/GetStaffs\\b')).reply(() => [200, GetStaffs])
  mock.onAny(new RegExp('/group/GetBedMembers\\b')).reply(() => [200, GetBedMembers])
  mock.onAny(new RegExp('/group/GetMemberBeds\\b')).reply(() => [200, GetMemberBeds])
  mock.onAny(new RegExp('/group/GetGroupList\\b')).reply(() => [200, GetGroupList])
  mock.onAny(new RegExp('/group/GetGroup\\b')).reply(() => [200, GetGroup])
  mock.onAny(new RegExp('/group/AddGroup\\b')).reply(() => [200, AddGroup])
  mock.onAny(new RegExp('/group/UpdateGroup\\b')).reply(() => [200, UpdateGroup])
  mock.onAny(new RegExp('/group/DeleteGroup\\b')).reply(() => [200, DeleteGroup])
})


const GetGroupList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "total": 4,
    "pageIndex": 0,
    "pageSize": 0,
    "groups": [
      {
        "groupID": 1,
        "groupName": "测试组别1",
        "isDefaultType": true,
        "isDefaultAlert": true,
        "isActive": true
      },
      {
        "groupID": 2,
        "groupName": "测试组别2",
        "isDefaultType": false,
        "isDefaultAlert": true,
        "isActive": false
      },
      {
        "groupID": 3,
        "groupName": "测试组别3",
        "isDefaultType": false,
        "isDefaultAlert": false,
        "isActive": true
      },
      {
        "groupID": 4,
        "groupName": "测试组别4",
        "isDefaultType": false,
        "isDefaultAlert": false,
        "isActive": true
      }
    ]
  }
}

const GetGroup = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "group": {
      "companyID": 1,
      "facilityID": 11,
      "staffIDs": [
        1,
        2
      ],
      "beds": [
        {
          "bedID": 2,
          "bedNumber": "9-002",
          "memberName": "陈大文"
        },
        {
          "bedID": 13,
          "bedNumber": "9-004",
          "memberName": "22"
        }
      ],
      "members": [
        {
          "memberID": 8,
          "memberName": "陈文文文文12",
          "bedNumber": "9-005"
        },
        {
          "memberID": 10,
          "memberName": "22",
          "bedNumber": "9-004"
        }
      ],
      "alertTimes": [
        {
          "alertTimeID": 7,
          "startTime": "09:00:00",
          "endTime": "12:00:00"
        },
        {
          "alertTimeID": 8,
          "startTime": "13:00:00",
          "endTime": "19:00:00"
        }
      ],
      "groupID": 2,
      "groupName": "测试组别2",
      "isDefaultType": false,
      "isDefaultAlert": true,
      "isActive": false
    }
  }
}

const AddGroup = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "floorID": 4
  }
}

const UpdateGroup = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const DeleteGroup = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const GetGroupDropDownList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "groups": [
      {
        "groupID": 1,
        "groupName": "組別1"
      },
      {
        "groupID": 2,
        "groupName": "組別2"
      },
    ]
  }
}

const GetStaffs = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "staffs": [
      {
        "staffID": 1,
        "staffName": "職員1"
      },
      {
        "staffID": 2,
        "staffName": "職員3"
      },
      {
        "staffID": 3,
        "staffName": "職員3"
      },
      {
        "staffID": 4,
        "staffName": "職員4"
      },
    ]
  }
}

const GetBedMembers = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "beds": [
      {
        "bedID": 1,
        "bedNumber": "101-01",
        "memberName": '張一'
      },
      {
        "bedID": 2,
        "bedNumber": "101-02",
        "memberName": '張二'
      },
      {
        "bedID": 3,
        "bedNumber": "101-03",
        "memberName": '張三'
      },
      {
        "bedID": 4,
        "bedNumber": "101-04",
        "memberName": '張四'
      },
    ]
  }
}

const GetMemberBeds = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "members": [
      {
        "memberID": 1,
        "bedNumber": "101-01",
        "memberName": '張一'
      },
      {
        "memberID": 2,
        "bedNumber": "101-02",
        "memberName": '張二'
      },
      {
        "memberID": 3,
        "bedNumber": "101-03",
        "memberName": '張三'
      },
      {
        "memberID": 4,
        "bedNumber": "101-04",
        "memberName": '張四'
      },
    ]
  }
}


