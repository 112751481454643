import axios from '@/libs/axios'
import axiosWehealth from '@/libs/axios-wehealth'
import axiosIoT from '@/libs/axios-IoT'
import MockAdapter from 'axios-mock-adapter'

const mock = new MockAdapter(axios, { delayResponse: 300 })
const mockWehealth = new MockAdapter(axiosWehealth, { delayResponse: 300 })
const mockIoT = new MockAdapter(axiosIoT, { delayResponse: 300 })

export default [mock, mockWehealth, mockIoT]
