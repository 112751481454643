import common from '@/common'
import * as signalR from '@microsoft/signalr'

let srAutoRestart = true
let srHubTimerId = null

// possible values of hub.state
// https://learn.microsoft.com/en-us/javascript/api/@microsoft/signalr/hubconnectionstate?view=signalr-js-latest
const srStateMap = {
  Connected: 'Connected',
  Connecting: 'Connecting',
  Disconnected: 'Disconnected',
  Disconnecting: 'Disconnecting',
  Reconnecting: 'Reconnecting',
}

// backend event
const srEventMap = {
  Message: 'Message',
  Test: 'Test',
  OnConnected: 'OnConnected',
  HeartBeat: 'HeartBeat',
  OverviewCount: 'OverviewCount',
  OverviewUpdate: 'OverviewUpdate',
  OverviewRemove: 'OverviewRemove',
  OverviewSilent: 'OverviewSilent',
  OverviewRefresh: 'OverviewRefresh',
  CallBellArmed: 'CallBellArmed',
  GeoFencingArmed: 'GeoFencingArmed',
}

// SignalR Hub
const srHub = new signalR.HubConnectionBuilder()
  .withUrl(process.env.VUE_APP_API_BASE_URL + 'realtime/signalhub', {
    accessTokenFactory: () => common.getAccessToken(),
    withCredentials: false,
  })
  .withAutomaticReconnect()
  .configureLogging(process.env.NODE_ENV === 'development' ? 4 : 4) // Information = 2, Warning = 3, Error = 4
  .build()

const srHubState = srHub.state

async function srStart(autoRestart = srAutoRestart) {
  if (common.config.is_request_mock || !common.config.is_signalr_enabled) return

  srAutoRestart = autoRestart
  clearTimeout(srHubTimerId)
  try {
    if (srHub.state === srStateMap.Disconnected) {
      await srHub.start()
      await srUpdateFilter(common.getFacilityId())
      srHubTimerId = null
    }
  } catch (err) {
    console.log(err)
    srHubTimerId = null
    if (err.message.indexOf('401') >= 0) {
      srAutoRestart = false
    } else if (srAutoRestart) {
      srHubTimerId = setTimeout(srStart, 5000)
    }
  }
}

async function srStop(autoRestart = srAutoRestart, restartTimeout = 5000) {
  srAutoRestart = autoRestart
  clearTimeout(srHubTimerId)
  srHubTimerId = null
  if (typeof (srHub.stop) === 'function') {
    await srHub.stop()
  }
  if (srAutoRestart) {
    srHubTimerId = setTimeout(srStart, restartTimeout)
  }
}

// Notify the srServer that the facility ID has changed so that "OverviewCount" will respond correctly.
async function srUpdateFilter(facilityID = common.getFacilityId()) {
  if (srHub.state === srStateMap.Connected) {
    await srHub.invoke('UpdateFilter', facilityID)
    return true
  } else {
    return false
  }
}

srHub.onreconnected(connectionId => {
  console.log('SignalR reconnected: ', connectionId)
  srUpdateFilter()
})

srHub.onreconnecting(error => {
  console.log('SignalR reconnect failed: ', error)
})

srHub.onclose(() => {
  console.log('SignalR connection closed.')
  if (srAutoRestart) {
    srStart(true)
  }
})

export { srHub, srHubState, srStart, srStop, srUpdateFilter, srStateMap, srEventMap }

