import mocks from '@/@fake-db/mockAdapters'
import samples from '@/@fake-db/responseSamples'

const ApiMap = {

  // bemOverview (deviceView)
  '/bemonitor/GetMonitorOverview': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'overview': {
        'count': {
          'offline': 3,
          'idle': 1,
          'onBed': 3,
          'offBed': 3,
          'online': 6,
          'total': 10
        },
        'rooms': [
          {
            'floorID': 5,
            'floorName': '5楼',
            'roomID': 9,
            'roomName': '501',
            'beds': [
              {
                'bedID': 1,
                'isSilent': true,
                'memberID': 1,
                'memberPhoto': 'files/MemberPhoto/MemberPhoto_1_20220529160233933.jpg',
                'bedNumber': '9-003',
                'latestUpdateTime': null,
                'isOnBed': null,
                'isOnline': null
              },
              {
                'bedID': 2,
                'isSilent': false,
                'memberID': 2,
                'memberPhoto': 'files/MemberPhoto/MemberPhoto_1_20220529224007654.jpeg',
                'bedNumber': '9-002',
                'latestUpdateTime': '2022-05-25T14:18:55',
                'isOnBed': true,
                'isOnline': true
              },
              {
                'bedID': 6,
                'isSilent': false,
                'memberID': 3,
                'memberPhoto': null,
                'bedNumber': '9-0021',
                'latestUpdateTime': null,
                'isOnBed': false,
                'isOnline': true
              }
            ]
          },
          {
            'floorID': 6,
            'floorName': '6楼',
            'roomID': 61,
            'roomName': '601',
            'beds': [
              {
                'bedID': 611,
                'isSilent': false,
                'memberID': 6111,
                'memberPhoto': null,
                'bedNumber': '6-001',
                'latestUpdateTime': null,
                'isOnBed': null,
                'isOnline': false
              },
              {
                'bedID': 612,
                'isSilent': true,
                'memberID': 6112,
                'memberPhoto': null,
                'bedNumber': '6-002',
                'latestUpdateTime': null,
                'isOnBed': null,
                'isOnline': false
              },
              {
                'bedID': 613,
                'isSilent': false,
                'memberID': 6113,
                'memberPhoto': null,
                'bedNumber': '6-003',
                'latestUpdateTime': '2022-05-25T14:18:55',
                'isOnBed': true,
                'isOnline': true
              },
              {
                'bedID': 614,
                'isSilent': true,
                'memberID': 6114,
                'memberPhoto': null,
                'bedNumber': '6-004',
                'latestUpdateTime': '2022-06-25T15:08:55',
                'isOnBed': true,
                'isOnline': true
              },
              {
                'bedID': 615,
                'isSilent': false,
                'memberID': 6115,
                'memberPhoto': null,
                'bedNumber': '6-005',
                'latestUpdateTime': null,
                'isOnBed': false,
                'isOnline': true
              },
              {
                'bedID': 616,
                'isSilent': true,
                'memberID': 6116,
                'memberPhoto': null,
                'bedNumber': '6-006',
                'latestUpdateTime': null,
                'isOnBed': false,
                'isOnline': true
              }
            ]
          }
        ]
      }
    }
  }],
  '/bemonitor/GetMonitorMemberInfo': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'memberInfo': {
        'chineseName': '陈1文',
        'englishName': 'Chan Da Wan',
        'floorName': '5楼',
        'roomName': '501',
        'bedNumber': '9-003'
      }
    }
  }],
  '/bemonitor/GetMonitorMemberRecords': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 14,
      'pageIndex': 0,
      'pageSize': 10,
      'records': [{
        'recordID': 14,
        'messageTime': '2022-08-03T22:57:16',
        'messageData': 'OnBed'
      }, {
        'recordID': 13,
        'messageTime': '2022-08-03T22:56:16',
        'messageData': 'OffBed'
      }, {
        'recordID': 12,
        'messageTime': '2022-08-03T22:55:16',
        'messageData': 'OnBedWithBlanket'
      }, {
        'recordID': 11,
        'messageTime': '2022-08-03T22:30:16',
        'messageData': 'OffBed'
      }, {
        'recordID': 10,
        'messageTime': '2022-08-03T22:19:47',
        'messageData': 'OnBed'
      }, {
        'recordID': 9,
        'messageTime': '2022-08-03T22:18:47',
        'messageData': 'OffBed'
      }, {
        'recordID': 7,
        'messageTime': '2022-08-03T22:17:47',
        'messageData': 'OnBedWithBlanket'
      }, {
        'recordID': 8,
        'messageTime': '2022-08-03T22:17:47',
        'messageData': 'OffBed'
      }, {
        'recordID': 6,
        'messageTime': '2022-08-03T22:16:47',
        'messageData': 'OnBed'
      }, {
        'recordID': 5,
        'messageTime': '2022-08-03T22:15:47',
        'messageData': 'OffBed'
      }]
    }
  }],

  // bemEnvironment (deviceEnv)
  '/bemonitor/GetMonitorList': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 4,
      'pageIndex': 0,
      'pageSize': 0,
      'monitors': [
        {
          'deviceMAC': 'AD01000EC2000607',
          'isUAT': true
        },
        {
          'deviceMAC': 'AD01000EC2000605',
          'isUAT': true
        },
        {
          'deviceMAC': 'AD01000EC2000603',
          'isUAT': false
        },
        {
          'deviceMAC': 'AD01000EC2000601',
          'isUAT': false
        }
      ]
    }
  }],
  '/bemonitor/AddMonitor': () => [200, samples.Success],
  '/bemonitor/AssignMonitor': () => [200, samples.Success],
  '/bemonitor/DeleteMonitor': () => [200, samples.Success],
  '/bemonitor/ExportMonitorList': () => [200, samples.IoTAccessError],

  // bemFacility (deviceManage)
  '/bemonitor/GetFacilityMonitorList': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 4,
      'pageIndex': 0,
      'pageSize': 0,
      'monitors': [
        {
          'status': 'Online',
          'companyName': '夕陽紅養老集團',
          'facilityName': '夕阳红唯一院舍',
          'monitorID': 1,
          'deviceMAC': '7b3b7b15a379452a',
          'floorName': '5楼',
          'roomName': '501',
          'bedNumber': '9-003'
        },
        {
          'status': 'Online',
          'companyName': '夕陽紅養老集團',
          'facilityName': '夕阳红唯一院舍',
          'monitorID': 2,
          'deviceMAC': 'aca0a4dfaf94c9d4',
          'floorName': '5楼',
          'roomName': '501',
          'bedNumber': '9-002'
        },
        {
          'status': 'Offline',
          'companyName': '夕陽紅養老集團',
          'facilityName': '夕阳红唯一院舍',
          'monitorID': 3,
          'deviceMAC': '192d1e02a50e4c70',
          'floorName': '5楼',
          'roomName': '501',
          'bedNumber': '9-0021'
        },
        {
          'status': 'Idle',
          'companyName': null,
          'facilityName': null,
          'monitorID': 4,
          'deviceMAC': '84441deacf135f3f',
          'floorName': null,
          'roomName': null,
          'bedNumber': null
        }
      ]
    }
  }],
  '/bemonitor/GetFacilityMonitor': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'monitor': {
        'monitorID': 4,
        'deviceMAC': '84441deacf135f3f',
        'companyID': null,
        'facilityID': null,
        'floorID': null,
        'roomID': null,
        'bedID': null
      }
    }
  }],
  '/bemonitor/UpdateFacilityMonitor': () => [200, samples.Success],

  // bemReport (deviceReport)
  '/bemonitor/GetReportChartGuidePath': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'path': 'files/SystemFile/WeHealth 2.0 Reports - Chart generation guideline.docx'
    }
  }],
  '/bemonitor/GetStatusReport': () => [200, samples.IoTAccessError],
  '/bemonitor/GetPersonalRecordReport': () => [200, samples.IoTAccessError],
  '/bemonitor/GetSleepCycleReport': () => [200, samples.IoTAccessError],
  '/bemonitor/GetTimePeriodStatReport': () => [200, samples.IoTAccessError],

  // bemForceSetting (deviceReset)
  '/bemonitor/GetForceSettingMonitorList': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 3,
      'pageIndex': 0,
      'pageSize': 0,
      'monitors': [
        {
          'monitorID': 1,
          'deviceMAC': '123',
          'floorName': '樓層1',
          'roomName': '房間1',
          'bedNumber': '床位1',
        },
        {
          'monitorID': 2,
          'deviceMAC': '456',
          'floorName': '樓層2',
          'roomName': '房間2',
          'bedNumber': '床位2'
        },
      ]
    }
  }],
  '/bemonitor/MonitorForceSetting': () => [200, samples.Success],

  // bemBatchUpload
  '/batch/BatchBEMonitors': () => [200, {
    'return_code': 0,
    'result_msg': 'success',
    'data': {
      'errorRow': 0,
      'errorContent': ''
    }
  }],
}

mocks.forEach(mock => {
  for (const api in ApiMap) {
    mock.onAny(new RegExp(`${api}\\b`))
      .reply(ApiMap[api])
  }
})
