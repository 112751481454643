import mocks from '@/@fake-db/mockAdapters'
import common from '@/common'
// import _ from 'lodash'

const assertions = {
  requireDemo: process.env.NODE_ENV === 'development' || process.env.VUE_APP_API_ENV === 'Dev',
  requireMain: common.config.is_request_mock,
  passThrough: !common.config.is_request_mock,
}

if (assertions.requireDemo) {
  require('./data/_demo')
}

if (assertions.requireMain) {
  require('./data/identity')
  require('./data/company')
  require('./data/facility')
  require('./data/bedType')
  require('./data/floor')
  require('./data/bed')
  require('./data/role')
  require('./data/staff')
  require('./data/member')
  require('./data/group')
  require('./data/bedExitMonitor')
  require('./data/callBell')
  require('./data/geoFencing')
  require('./data/notice')
  require('./data/admin')
  require('./data/kiosk')
  require('./data/IoT')
}

if (assertions.passThrough) {
  mocks.forEach(mock => {
    mock.onAny()
      .passThrough() // forwards the matched request over network
  })
}
