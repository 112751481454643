export default [
  {
    name: 'callBellRecord',
    path: '/callBellRecord',
    component: () => import('@/views/callBell/cbRecord/Index')
  },
  {
    name: 'callBellEnvironment',
    path: '/callBellEnvironment',
    component: () => import('@/views/callBell/cbEnvironment/Index')
  },
  {
    name: 'callBellEnvironment_add',
    path: '/callBellEnvironment/add',
    component: () => import('@/views/callBell/cbEnvironment/Add')
  },
  {
    name: 'callBellEnvironment_edit',
    path: '/callBellEnvironment/edit/:mac/:env/:name',
    component: () => import('@/views/callBell/cbEnvironment/Edit')
  },
  {
    name: 'callBellFacility',
    path: '/callBellFacility',
    component: () => import('@/views/callBell/cbFacility/Index')
  },
  {
    name: 'callBellFacility_edit',
    path: '/callBellFacility/edit/:id',
    component: () => import('@/views/callBell/cbFacility/Edit')
  },
  {
    name: 'callBellNfcManagement',
    path: '/callBellNfcManagement',
    component: () => import('@/views/callBell/cbNfcManagement/Index')
  },
  {
    name: 'callBellReport',
    path: '/callBellReport',
    component: () => import('@/views/callBell/cbReport/Index')
  },
  {
    name: 'callBellRawDown',
    path: '/callBellRawDown',
    component: () => import('@/views/callBell/cbRawDown/Index')
  },
  {
    name: 'callBellRawTech',
    path: '/callBellRawTech',
    component: () => import('@/views/callBell/cbRawTech/Index')
  },
  {
    name: 'callBellBatchUpload',
    path: '/callBellBatchUpload',
    component: () => import('@/views/callBell/cbBatchUpload/Index')
  },
  {
    name: 'callBellPatrolRecord',
    path: '/callBellPatrolRecord',
    component: () => import('@/views/callBell/cbPatrolRecord/Index')
  },
  {
    name: 'callBellPatrolReport',
    path: '/callBellPatrolReport',
    component: () => import('@/views/callBell/cbPatrolReport/Index')
  },
  {
    name: 'callBellBattery',
    path: '/callBellBattery',
    component: () => import('@/views/callBell/cbBattery/Index')
  },
  // {
  //   name: 'callBellPatrolRawDown',
  //   path: '/callBellPatrolRawDown',
  //   component: () => import('@/views/callBell/callBellPatrolRawDown/Index')
  // },
]
