<template>
  <div class="toast-main" @click="$emit('confirm')">

    <div class="toast-content font-weight-bold text-dark d-flex">
      <div class="icon-wrapper bg-danger">
        <feather-icon
          icon="BellIcon"
          size="24"
          class="text-white"
        />
      </div>
      <div>
        <h4 class="text-danger">{{ title }}</h4>
        <div class="text-secondary">
<!--          <div>緊急鍵名稱：Button-A01</div>-->
<!--          <div>會員：陳大文 (101-01)</div>-->
<!--          <div>時間：2022-09-27 16:12:57</div>-->
          <div v-for="row in rows">{{ row }}</div>
        </div>
      </div>
    </div>

    <div class="toast-bottom d-flex justify-content-end" v-if="false">
<!--      <b-button variant="secondary" class="btn-sm float-right ml-1" @click="$emit('close-toast')">關閉</b-button>-->
      <b-button variant="primary" class="btn-sm" @click="$emit('confirm')">查看</b-button>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    title: { type: String },
    rows: { type: Array }
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.toast-main {
  cursor: pointer;
}
.toast-content {
  margin-bottom: 5px;
}
.icon-wrapper {
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 24px;
  width: 24px;
  margin-right: 10px;
  .feather {
    height: 24px;
    width: 24px;
    padding: 5px;
    display: block;
    box-sizing: border-box;
  }
}
</style>
